import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import Skeleton from 'react-loading-skeleton'
import constants from 'lib/constants'
import notify from 'lib/errors'
import { publishEvent } from 'lib/events/tracking'

import useFetchBuilder from 'lib/hooks/useFetchBuilder'
import Layout from 'components/Layout'
import Event from 'types/Event'

const BuilderComponent = dynamic(() => import('components/core/BuilderComponent'), { ssr: false })

const LandingPage = () => {
  const router = useRouter()
  const { asPath } = router

  const [url, anchor] = asPath.split('#')
  const model = 'landing-pages'
  const { builderPage, error: fetchBuilderError } = useFetchBuilder({ url, model })
  const { id: builderPageId, data: builderPageData } = builderPage || {}

  useEffect(() => {
    publishEvent(Event.Page.PAGE_VIEW)
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      // Redirect to new page after 5 seconds if builderPage is still not found
      if (!builderPage && fetchBuilderError) {
        notify(new Error('Building LP model not found'), {
          metadata: { url, anchor },
          metadataName: 'LandingPage Builder error',
        })
        router.push('/')
      }
    }, 5000)

    // Clear the timer on component unmount or when builderPage is set
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [builderPageId, asPath, fetchBuilderError])

  const updateHash = data => {
    if (anchor && data) {
      setTimeout(() => {
        const el = global.document?.getElementById(anchor)
        el?.scrollIntoView()
      }, 100)
    }
  }

  return (
    <Layout showFooter={!!builderPage}>
      <Head>
        <title>{builderPageData?.ogTitle}</title>
        <meta property="description" name="description" content={builderPageData?.ogDescription} />
        <meta property="og:title" content={builderPageData?.ogTitle} />
        <meta property="og:description" content={builderPageData?.ogDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`${constants.URL_BASE}${url}`} />
        <meta property="og:site_name" content="Everlane" />
        <meta property="og:image" name="og:image" content={builderPageData?.ogImageUrl} />
        <link rel="canonical" href={url} />
      </Head>
      <div>
        {builderPage ? (
          <BuilderComponent model={model} url={url} contentLoaded={data => updateHash(data)} />
        ) : (
          <Skeleton height="50vh" width="100vw" />
        )}
      </div>
    </Layout>
  )
}

export default LandingPage
